import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image-es5"
import Tick from "../../../images/icon-campaign-3.png"
import Coins from "../../../images/icon-campaign-4.png"
import ArrowRight from "../../../images/icon-campaign-5.png"

import "./hero-banner-campaign.scss"

export const HeroBannerLloyds = ({
  title,
  subtitle,
  description,
  backgroundImage,
  callToAction,
}) => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "section-page-banner-short.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const handleNext = () => {
    if (counter !== chatboxV2.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 8000);
    return () => clearInterval(interval);
  });

  const chatboxV2 = [
    {
      title: "I can only be very grateful that your services are out there and available to people like myself.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Your service from start to finish has been amazing and the peace of mind it gave me allowed me to focus on getting debt free which I have now achieved.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Business Debtline were thorough, informative, explained all options clearly to me, non judgmental, and made me feel there were solutions to my issues.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "I appreciated the patience and knowledge shown and shared with me.  I would highly recommend Business Debtline to anyone experiencing financial difficulties.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Business Debtline gave me a way out to sort something I didn't feel in control of - I feel very grateful.",
      subtitle: "Business Debtline caller, 2023"
    },
    {
      title: "Contact with Business Debtline helped me to take back control of my finances.  I'm working my way towards being debt free.  I sleep much better at night.",
      subtitle: "Business Debtline caller, 2023"
    },
  ];

  return (
<>
<div className="p-0 jumbotron hero-banner">
  <div className="p-4 p-sm-5">
    <h1 className="hero-banner-title">Business Debtline & Lloyds Bank</h1>
    <div className="hero-banner-description">Working together to help you</div>
  </div>

  <div className="arrow-banner-with-logo"></div>
</div>
<div className="row">
        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-8 col-12 mb-4">
              <div className="card green-border" style={{ borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Business Debtline and LLoyds Bank are here to help you get a fresh start with your finances.</h2>
                    <div className="break-down" >
                      <div className="" >
                      <p>Business Debtline is a registered charity. Our service is always free and our advisers give impartial, non-judgmental debt advice. We won't share what you tell uss with LLoyds Bank - you can always speak to us in confidence.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex" style={{ marginTop: "20px"}}>
                <div className="col-md-12 col-12 mb-4">
                  <div className="card green-border" style={{ border : "4px solid #573085", borderRadius: "20px" }}>
                    <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between align-center">
                      <div className="card-body-content justify-content-sm-between break-down">
                       <div className="click-here" style={{ display: "flex", justifyContent:"center", alignItems: "center" }} >
                        <a className="lead btn-lg d-inline-block hover-effect-btn"
                          href="https://mybudget.businessdebtline.org/"
                          role="button"
                          style={{ fontFamily: "FilsonProRegular", textDecoration: "none", color: "#FFFFFF", borderRadius: "15px", backgroundColor:"#573085",  textAlign: "center", width: "100%"  }}
                        >
                          Register/sign in
                        </a>
                        </div> 
                        <h2 className="h4 side-register" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430",  marginTop: "8px" }}>Use My Budget now or sign back in to your account.</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-4 d-flex">
              {
                chatboxV2.map((box, index) => (
                  <div key={index} className={counter - 1 === index ? "show-campaign" : "not-show-campaign"} style={{ borderRadius: "11px", position: "relative" }} >
                    <div className="p-0 hero-banner-campaign bubble2" style={{ position: "relative" }}>
                      <div className="p-3" style={{ backgroundColor: "#0c376b", width: "100%", borderRadius: "20px" }}>
                        <p className="campaign2-title">{box.title}</p>
                        <div className="sub-bubble2" >
                          <div style={{ fontFamily: "Filsonsoft" }}>{box.subtitle}</div>
                        </div>
                      </div>
                    </div>

                    <div className="sub-bubble-mobile-wrapper">
                      <div className="wrap-sub-bubble-small">
                        <div className="sub-bubble-mobile2">
                          <h2 style={{ color: "#FFFFFF", fontFamily: "Filsonsoft" }}>{box.subtitle}</h2>
                        </div>
                      </div>
                    </div>
                  </div>

                ))
              }
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-4 col-12 mb-4 d-flex">
              <div className="card image-mobile" style={{ borderRadius : "20px" }}>             
                <iframe className="full-height-video" src="https://www.youtube.com/embed/c0g42UVK6-E?si=fV3w_gZP030-O49X" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe>              
              </div>
            </div>
            <div className="col-md-8 col-12 mb-4 d-flex">
              <div className="card green-border" style={{ borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>How it works</h2>                    
                      <p><strong>1. Complete a budget using our online tool (you can save and come back to it)</strong>. We know doing a budget can feel like hard work, but it is worth it. An accurate budget allows us to give you the best advice and tell you what debt solutions can help you.</p>
                      <p><strong>2. Book an appointment online with a Business Debtline specialist adviser</strong>. During the phone appointment, your adviser will give you the advice you need on your debts, your budget and your rights and options. This will be based on the budget you've done, which your adviser can see - so no repeating yourself.</p>
                      <p><strong>3. Take your next steps to sorting out your debts</strong>. Business Debtline are here for you - we'll tell you what to do next and support you every step of the way.</p>                                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="row d-flex h-100">
            <div className="col-md-8 col-12 mb-4">
              <div className="card green-border" style={{ border : "4px solid #573085", borderRadius : "20px" }}>
                <div className="card-body d-sm-flex flex-sm-column justify-content-sm-between">
                  <div className="card-body-content">
                    <h2 className="h4 mb-3" style={{ fontFamily: "FilsonProBold", fontSize: "1.5em", color: "#001430" }}>Using My Budget</h2>
                    <div className="no-break-down" >
                      <div className="icon-no-break-down">
                        <img src={Tick} alt="Tick" width="100%"/>
                      </div>
                      <div className="icon-no-break-down">
                        <img src={Coins} alt="Coins" width="100%"/>
                      </div>
                      <div className="icon-no-break-down">
                        <img src={ArrowRight} alt="ArrowRight" width="100%"/>
                      </div>
                    </div>
                    <p>Dealing with business debts can be complicated, but you don't need to do this alone.</p>
                    <p>We help over 37,000 people and business in different circumstances every year.</p>
                    <p>Creating a budget using our tool before you speak with us is the best way to prepare for a call. Our advisers will use the information in the budget to explain your options.</p>
                    <p>You will get your own personalised budget and you can securely save your information and go back to it as many times as you want to.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mb-4 d-flex" >
              <div className="card" style={{ backgroundColor: "#f2f2f2", borderRadius: "20px", width: "100%"}} >
              <div className="card-body break-down d-sm-flex flex-sm-column justify-content-sm-between" >
                <div className="card-body-content wrap-subtext-mobile">
                  <div className="different-situation" style={{ fontFamily: "FilsonProRegular"}}>Everyone has a different situation. If you're struggling with debt, <span style={{ fontFamily: "FilsonProBold", }}>start using our My Budget tool</span> now or <span style={{ fontFamily: "FilsonProBold", }}>sign back in to update your account.</span></div>
                </div>
                <div className="be-center">
                  <a
                    className="lead btn-lg d-inline-block hover-effect-btn"
                    href="https://mybudget.businessdebtline.org/"
                    role="button"
                    style={{ fontFamily: "FilsonProRegular", textDecoration:"none", borderRadius: "15px",  backgroundColor:"#573085", color:"#FFFFFF", width: "100%", textAlign:"center" }}
                  >
                   Register/sign in
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      
        <div class="col-md-12 col-12 mb-4 d-flex">
          <div class="card default">
            <div class="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
              <div class="card-body-content">
                <div>
                  <h4><strong>Getting extra help if you need it</strong></h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-4 d-flex">
          <div class="card green-border">
            <div class="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
              <div class="card-body-content">
                <h2 class="h4 mb-3 ">Emergency situations</h2>
                <div>
                  <p>Get advice straightaway if you are at immediate risk of losing your home, business premises, essential services, or goods, such as your car. Read more about <a href="https://businessdebtline.org/fact-sheet-library/emergency-situations-ew/">emergency debt advice</a> or call us on <a href="tel:0800 197 6026">0800 197 6026</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-4 d-flex">
          <div class="card green-border">
            <div class="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
              <div class="card-body-content">
                <h2 class="h4 mb-3 ">Sharing your information</h2>
                <div>
                  <p>If you have a personal, health or other issue that is making it harder to manage your finances, you can tell us.</p>
                  <p>If you tell us, it could help us to provide you with a better service. We'll also keep the information confidential.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-4 d-flex">
          <div class="card green-border">
            <div class="card-body d-sm-flex flex-sm-column justify-content-sm-between p-3">
              <div class="card-body-content">
                <h2 class="h4 mb-3 ">Need extra support?</h2>
                <div>
                  <p>We work with other organisations who can provide extra support depending on your circumstances. Read more about <a href="https://businessdebtline.org/referrals-extra-support-ew/">getting extra support</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</>
  )
}

HeroBannerLloyds.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  callToAction: PropTypes.shape({
    href: PropTypes.string,
    ariaLabel: PropTypes.string,
    label: PropTypes.string,
  }),
}
